import React from 'react';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { classes } from './Login.st.css';
import { DataHooks } from './constats';
import {
  TextButton,
  TextButtonPriority as TEXT_BUTTON_PRIORITY,
  Text,
} from 'wix-ui-tpa/cssVars';
import { LoginViewModel } from '../../ViewModel/loginViewModel/loginViewModel';

export interface LoginProps {
  viewModel?: LoginViewModel;
}

const Login: React.FC<loginprops> = ({ viewModel }) => {
  const { onLoginClick } = useCalendarActions();
  return viewModel ? (
    <div className="{classes.root}" data-hook="{DataHooks.LOGIN_SECTION}">
      <text className="{classes.content}" data-hook="{DataHooks.LOGIN_TEXT}">
        { viewModel?.content}{' '}
      </text>
      <textbutton priority="{TEXT_BUTTON_PRIORITY.link}" className="{classes.cta}" data-hook="{DataHooks.LOGIN_BUTTON}" onClick="{()" ==""> onLoginClick()}
      >
        {' '}
        {viewModel?.cta}
      </textbutton>
    </div>
  ) : null;
};

export default Login;
</loginprops>